<template>
  <div>
    <div
      ref="buttonRef"
      class="inline-block cursor-pointer"
      :class="buttonClass"
      @click="openPopper"
    >
      <slot name="button" />
    </div>
    <div ref="contentRef" class="z-10" :class="dropdownClass">
      <transition
        enter-active-class="transition duration-100 ease-out"
        enter-class="transform scale-75 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-class="transform scale-100 opacity-100 "
        leave-to-class="transform scale-75 opacity-0"
      >
        <div
          v-show="isOpen"
          class="w-56 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5"
          :class="contentClass"
        >
          <div
            class="py-1 text-left"
            role="menu"
            aria-orientation="vertical"
            aria-label="Dropdown"
            @click="isOpen = !isOpen"
          >
            <slot name="content" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup>
import { createPopper, detectOverflow } from '@popperjs/core'
import { onClickOutside } from '@vueuse/core'

const { timeout } = useCommon()

const props = defineProps({
  placement: {
    type: String,
    default: 'left'
  },
  offset: {
    type: Array,
    default: () => [15, -10]
  },
  buttonClass: {
    type: String,
    default: ''
  },
  dropdownClass: {
    type: String,
    default: ''
  },
  contentClass: {
    type: String,
    default: ''
  }
})

const buttonRef = ref(null)
const contentRef = ref(null)
const popperInstance = ref(null)

const popperOptions = {
  placement: props.placement,
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: props.offset
      }
    },
    {
      name: 'flip',
      options: {
        fallbackPlacements: ['bottom-start', 'left', 'bottom-start']
      }
    }
  ]
}

const isOpen = ref(false)

const openPopper = async () => {
  isOpen.value = !isOpen.value
  await timeout(50)
  const height = contentRef.value.clientHeight
  const overflow = detectOverflow(popperInstance.value.state, popperOptions)
  if (overflow.bottom + height > 0) {
    popperInstance.value.setOptions(popperOptions)
  }
}

onClickOutside(buttonRef, () => {
  isOpen.value = false
})

onMounted(() => {
  popperInstance.value = createPopper(
    buttonRef.value,
    contentRef.value,
    popperOptions
  )
})
</script>
